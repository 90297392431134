import { Injectable } from '@angular/core';
import { FiResourceService, FiUrlService, Resource } from '@fi-sas/webpage/libs/core';
import { CompanyUserModel } from '../models/company-user.model';
import { Repository } from '@fi-sas/webpage/shared/repository/repository/repository.class';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService extends Repository<CompanyUserModel> {
  constructor(resourceService: FiResourceService, urlService: FiUrlService) {
    super(resourceService, urlService);

    this.entities_url = "INTERNSHIPS.COMPANY_USER";
    this.entity_url = "INTERNSHIPS.COMPANY_USER_ID";
  }

  getCompanyUser() {
    return this.resourceService.list<CompanyUserModel>(
      this.urlService.get('INTERNSHIPS.COMPANY_USER_LOGGED'),
    );
  }

  getAdvisors() {
    return this.resourceService.list<CompanyUserModel>(
      this.urlService.get('INTERNSHIPS.ADVISORS'),
    );
  }
}
