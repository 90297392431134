import { LoginSuccessModel } from "../models/login-success.model";
import { Observable, BehaviorSubject } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { UserModel } from "../models/user.model";
import { FiResourceService, FiUrlService, Resource } from "@fi-sas/core";
import { first, tap } from "rxjs/operators";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { SSOurl } from "../models/sso-url.model";
import { FirstLogin } from "../models/first-login.model";
import { ChangePin } from "../models/change-pin.model";
import { ChangePassword } from "../models/change-password.model";
import { PersonalDataModel } from "../models/personal-data.model";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { CCSamlRequest } from "../models/cc-saml-request.model";
import { DocumentTypeModel } from "../models/document-type.model";
import { Location } from "@angular/common";
import { ResetCredentialsModel } from "../models/reset-credentials.model";
import { CredentialsModel } from "../models/credentials.model";
import { NewPasswordModel } from "../models/new-password.model";
import { CompanyUserService } from "@fi-sas/webpage/modules/users/services/company-user.service";
import { CompanyUserModel, CompanyUserType } from "@fi-sas/webpage/modules/users/models/company-user.model";
import { UiService } from "@fi-sas/webpage/core/services/ui.service";

export interface AuthObject {
  user?: UserModel;
  companyUser?: CompanyUserModel;
  scopes: string[];
  token: string;
  expires_in: Date;
  authDate: Date;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _token: string | null = null;
  private _authObj: AuthObject | null = null;

  userSubject: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);

  isLogged = false;
  isLoggedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isGuest = false;
  isGuestSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isMentor = false;
  isMentorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isAdvisor = false;
  isAdvisorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isManager = false;
  isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isRefreshing = false;
  $isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private resourceService: FiResourceService,
    private urlService: FiUrlService,
    private injector: Injector,
    private companyUserService: CompanyUserService,
    private location: Location
  ) {
    this.changeIsGuest(false);
    this.changeIsAdvisor(false);
    this.changeIsMentor(false);
    this.changeIsManager(false);
    this.changeIsLogged(false);
  }

  /***
   * Check's the credentials and add to the storage the login data
   * @param email
   * @param password
   */
  login(email: string, password: string): Observable<AuthObject> {
    return new Observable<AuthObject>((observer) => {
      this.resourceService
        .create<LoginSuccessModel>(
          this.urlService.get("AUTH.LOGIN"),
          {
            email,
            password,
          },
          {
            headers: new HttpHeaders()
              .append("no-error", "true")
              .append("no-token", "true"),
            withCredentials: true,
          }
        )
        .pipe(first())
        .subscribe(
          (value) => {
            this._token = value.data[0].token;
            this.createAuthObject(value.data[0]).subscribe(
              (res) => {
                observer.next(res);
                observer.complete();
              },
              (err) => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  /***
   * Creates a token guest
   */
  loginGuest(): Promise<AuthObject> {
    return new Promise<AuthObject>((resolve, reject) => {
      this.resourceService
        .create<LoginSuccessModel>(
          this.urlService.get("AUTH.LOGIN"),
          {},
          {
            headers: new HttpHeaders()
              .append("no-error", "true")
              .append("no-token", "true"),
            withCredentials: true,
          }
        )
        .pipe(first())
        .subscribe(
          (value) => {
            this.createAuthObject(value.data[0]).subscribe(
              (res) => {
                resolve(res);
              },
              (err) => {
                reject(err);
              }
            );
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  /***
   * Get url to reddirect to the login page of the idp
   */
  ssoLoginUrl(): Observable<Resource<SSOurl>> {
    return this.resourceService
      .read<SSOurl>(this.urlService.get("AUTH.SSO_URL"))
      .pipe(first());
  }

  ssoCCLoginSAMLRequest(): Observable<Resource<CCSamlRequest>> {
    return this.resourceService
      .read<CCSamlRequest>(this.urlService.get("AUTH.SSO_CC_URL"))
      .pipe(first());
  }

  private changeIsGuest(isGuest: boolean) {
    this.isGuest = isGuest;
    this.isGuestSubject.next(this.isGuest);
  }

  getIsGuest() {
    return this.isGuest;
  }

  getIsGuestObservable() {
    return this.isGuestSubject.asObservable();
  }

  private changeIsMentor(isMentor: boolean) {
    this.isMentor = isMentor;
    this.isMentorSubject.next(this.isMentor);
  }

  getIsMentor() {
    return this.isMentor;
  }

  getIsMentorObservable() {
    return this.isMentorSubject.asObservable();
  }

  private changeIsAdvisor(isAdvisor: boolean) {
    this.isAdvisor = isAdvisor;
    this.isAdvisorSubject.next(this.isAdvisor);
  }

  getIsAdvisor() {
    return this.isAdvisor;
  }

  getIsAdvisorObservable() {
    return this.isAdvisorSubject.asObservable();
  }

  private changeIsManager(isManager: boolean) {
    this.isManager = isManager;
    this.isManagerSubject.next(this.isManager);
  }

  getIsManager() {
    return this.isManager;
  }

  getIsManagerObservable() {
    return this.isManagerSubject.asObservable();
  }

  private changeIsLogged(isLogged: boolean) {
    this.isLogged = isLogged;
    this.isLoggedSubject.next(this.isLogged);
  }

  getIsLogged() {
    return this.isLogged;
  }

  getIsLoggedObservable() {
    return this.isLoggedSubject.asObservable();
  }

  /***
   * Creates the auth objects after authentication
   */
  createAuthObject(result: LoginSuccessModel): Observable<AuthObject> {
    return new Observable<AuthObject>((observer) => {
      const authObj: AuthObject = {
        authDate: new Date(),
        token: result.token,
        expires_in: result.expires_in_ms ? new Date(Date.now() + result.expires_in_ms) : new Date(result.expires_in),
        scopes: this._authObj && this._authObj.scopes ? this._authObj.scopes : [],
        user: undefined,
        companyUser: null
      };

      this.saveAuthObj(authObj);

      // ALERT TOKEN REFRESH
      this.setRefreshingToken(false);

      this.resourceService
        .read<UserModel>(this.urlService.get("AUTH.LOGIN_USER"), {})
        .pipe(first())
        .subscribe(
          async (value1) => {
            const isGuest = value1.data[0].user_name === "guest";

            if (!isGuest) {
              authObj.user = value1.data[0];

              const aux = await this.companyUserService.getCompanyUser().toPromise();
              if (aux.data.length > 0) {
                authObj.companyUser = aux.data[0];

                if ((aux.data[0].type as any).includes("MENTOR")) {
                  this.changeIsMentor(true)
                  this.isMentorSubject.next(true)
                }
                if ((aux.data[0].type as any).includes("ADVISOR")) {
                  this.changeIsAdvisor(true)
                  this.isAdvisorSubject.next(true)
                }
                if ((aux.data[0].type as any).includes("MANAGMENT")) {
                  this.changeIsManager(true)
                  this.isManagerSubject.next(true)
                }
              } else {
                this.changeIsMentor(false)
                this.isMentorSubject.next(false)
                this.changeIsAdvisor(false)
                this.isAdvisorSubject.next(false)
                this.changeIsManager(false)
                this.isManagerSubject.next(false)
              }
              this.userSubject.next(authObj.user);

            }

            if (isGuest) {
              this.changeIsGuest(true);
              this.changeIsLogged(false);
            } else {
              this.changeIsGuest(false);
              this.changeIsLogged(true);
            }

            this.validateToken()
              .pipe(first())
              .subscribe(
                () => {
                  observer.next(authObj);
                  observer.complete();
                },
                (error) => {
                  observer.error(error);
                  observer.complete();
                }
              );
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  /***
   * Remove the data of the login
   */
  logout(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this._token = null;
      this._authObj = null;
      this.userSubject.next(null);

      this.changeIsGuest(false);
      this.changeIsLogged(false);

      this.resourceService
        .read<any>(this.urlService.get("AUTH.LOGOUT", { type: "WEB" }), {
          headers: new HttpHeaders().append("no-error", "true"),
          withCredentials: true,
        })
        .pipe(first())
        .subscribe((value) => {
          this.loginGuest()
            .then(() => {
              observer.next(true);
              observer.complete();
            })
            .catch(() => {
              observer.next(false);
              observer.complete();
            });
        })
    });
  }

  /***
   * Return the user data of the login data
   */
  getAuth(): AuthObject | null {
    return this._authObj;
  }

  /***
   * Return the user data of the login data
   */
  getUser(): UserModel | null {
    const auth = this.getAuth();
    if (auth !== null && auth !== undefined) {
      return auth.user ? auth.user : null;
    } else {
      return null;
    }
  }

  /***
   * Update the user object
   * @param user
   */
  setUser(user: UserModel): UserModel | null {
    const authObj = this.getAuth();
    if (authObj) {
      authObj.user = user;
      this.userSubject.next(authObj.user);
      this.saveAuthObj(authObj)
      const authResult = this.getAuth();
      return authResult && authResult.user ? authResult.user : null;
    }
    return null;
  }

  /***
   * Return the scopes of the user
   */
  getScopes(): string[] | null {
    const auth = this.getAuth();
    if (auth !== null) {
      return auth.scopes;
    } else {
      return null;
    }
  }

  /***
   * Update the scopes of the user
   */
  setScopes(scopes: string[]): string[] | null {
    const auth = this.getAuth();
    if (auth !== null) {
      auth.scopes = scopes;
      return auth.scopes;
    } else {
      return null;
    }
  }

  /***
   * Return true if the user have the scope send by param
   * if send only scope microservice:entity checks if have all
   * permissions if send microservice:entity:(crud operation) check if has
   * this permission
   * @param scope
   */
  hasPermission(scope: string): boolean {
    if (scope == null || scope == "" || scope == undefined) {
      return false;
    }

    const scopes = this.getScopes();

    return scopes != undefined &&
      scopes.includes(scope) != undefined
      ? scopes.includes(scope)
      : false;
  }

  /***
   * Return a Observable of the user object
   */
  getUserObservable(): Observable<UserModel | null> {
    return this.userSubject.asObservable();
  }

  /***
   * Returns the token of the user logged
   */
  getToken(): string | null {
    const auth = this.getAuth();
    return auth ? auth.token : null;
  }

  private setRefreshingToken(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
    this.$isRefreshing.next(this.isRefreshing);
  }

  refreshToken(): Observable<any> {
    return new Observable((observer) => {
      this.setRefreshingToken(true);

      this.resourceService
        .create<LoginSuccessModel>(
          this.urlService.get("AUTH.REFRESH_TOKEN", { type: "WEB" }),
          {},
          {
            headers: new HttpHeaders()
              .append("no-error", "true")
              .append("no-token", "true"),
            withCredentials: true,
          }
        )
        .pipe(first())
        .subscribe(
          (value) => {
            this._token = value.data[0].token;
            this.createAuthObject(value.data[0])
              .pipe(first())
              .subscribe(
                (res) => {
                  observer.next(res);
                  observer.complete();
                },
                (err) => {
                  observer.error(err);
                  observer.complete();
                }
              );
          },
          (err) => {
            this.loginGuest()
              .then((res) => {
                observer.next(false);
                observer.complete();
                this.setRefreshingToken(false);
              })
              .catch((err1) => {
                observer.next(false);
                observer.complete();
                this.setRefreshingToken(false);
              });
          }
        );
    });
  }

  /***
   * Validate the token and updates the scopes of the user
   */
  validateToken(): Observable<boolean> {
    return new Observable((observable) => {
      if (!this.getToken()) {
        observable.next(false);
        observable.complete();
      } else {
        this.resourceService
          .create<{ user: UserModel; scopes: string[] }>(
            this.urlService.get("AUTH.VALIDATE_TOKEN"),
            { token: this.getToken() }
          )
          .pipe(first())
          .subscribe(
            (value) => {
              if (value.data.length === 0) {
                observable.next(false);
                observable.complete();
              } else {
                this.setUser(value.data[0].user);
                this.setScopes(value.data[0].scopes);
                // this.saveAuthObj();
                observable.next(true);
                observable.complete();
              }
            },
            (err) => {
              observable.error(err);
              observable.complete();
            }
          );
      }
    });
  }

  /***
   * Save the auth obj to local storage
   */
  private saveAuthObj(authObj: AuthObject) {
    this._authObj = authObj;
  }

  changePassword(
    changePassword: ChangePassword
  ): Observable<Resource<UserModel>> {
    return this.resourceService.create<UserModel>(
      this.urlService.get("USERS.CHANGE_PASSWORD"),
      changePassword
    );
  }
  changePin(changePin: ChangePin): Observable<Resource<any>> {
    return this.resourceService.create<any>(
      this.urlService.get("USERS.CHANGE_PIN"),
      changePin
    );
  }

  randomPin(): Observable<Resource<any>> {
    return this.resourceService.create<any>(
      this.urlService.get("USERS.RANDOM_PIN"),
      ""
    );
  }

  resetPin(
    resetPin: ResetCredentialsModel
  ): Observable<Resource<CredentialsModel>> {
    return this.resourceService.create<CredentialsModel>(
      this.urlService.get("USERS.RESET_PIN"),
      resetPin
    );
  }

  confirmPin(token: string): Observable<Resource<UserModel>> {
    let params = new HttpParams();
    params = params.set("token_validate", token);
    return this.resourceService.create<UserModel>(
      this.urlService.get("USERS.CONFIRM_PIN"),
      {},
      { params }
    );
  }

  resetPassword(
    resetPassword: ResetCredentialsModel
  ): Observable<Resource<CredentialsModel>> {
    return this.resourceService.create<CredentialsModel>(
      this.urlService.get("USERS.RESET_PASSWORD"),
      resetPassword
    );
  }

  resetCompanyPassword(resetPassword: ResetCredentialsModel): Observable<Resource<CredentialsModel>> {
    return this.resourceService.create<CredentialsModel>(
      this.urlService.get("CRM.RESET_PASSWORD"),
      resetPassword
    );
  }

  newPassword(newPassword: NewPasswordModel): Observable<Resource<UserModel>> {
    return this.resourceService.create<UserModel>(
      this.urlService.get("USERS.NEW_PASSWORD"),
      newPassword
    );
  }

  firstLogin(firstLogin: FirstLogin): Observable<Resource<UserModel>> {
    return this.resourceService.create<UserModel>(
      this.urlService.get("USERS.FIRST_LOGIN"),
      firstLogin
    );
  }

  isProfileDataCompleted(
    router: Router,
    translateService: TranslateService,
    modalService: NzModalService
  ): Promise<boolean> {
    const requiredFields: string[] = [
      "name",
      "birth_date",
      "gender",
      "phone",
      "identification",
      "tin",
      "address",
      "city",
      "postal_code",
      "country",
    ];
    const user = this.getUser();
    if (!user) {
      return Promise.resolve(false);
    }
    const anyUser: { [index: string]: any } = { ...user };

    for (const field of requiredFields) {
      if (!anyUser[field]) {
        setTimeout(() => {
          translateService
            .get([
              "MISC.CANCEL",
              "CORE.UPDATE_PROFILE.EDIT_PROFILE",
              "CORE.UPDATE_PROFILE.TITLE",
              "CORE.UPDATE_PROFILE.CONTENT",
            ])
            .pipe(first())
            .subscribe((translation) => {
              modalService.confirm({
                nzTitle: translation["CORE.UPDATE_PROFILE.TITLE"],
                nzContent: translation["CORE.UPDATE_PROFILE.CONTENT"],
                nzCancelText: translation["MISC.CANCEL"],
                nzOkText: translation["CORE.UPDATE_PROFILE.EDIT_PROFILE"],
                nzOnCancel: () => {
                  this.location.back();
                },
                nzOnOk: () => {
                  router.navigate(["/personal-data"]);
                },
              });
            });
        }, 500);
        return Promise.resolve(false);
      }
    }
    return Promise.resolve(true);
  }

  updateMe(personalData: PersonalDataModel): Observable<Resource<UserModel>> {
    return this.resourceService.create<UserModel>(
      this.urlService.get("USERS.UPDATE_ME"),
      personalData
    ).pipe(tap(res => {
      this._authObj.user = res.data[0];
    }));
  }

  getUserInfoByToken(): Observable<Resource<UserModel>> {
    return this.resourceService.read<UserModel>(
      this.urlService.get("AUTH.LOGIN_USER")
    );
  }

  verifyAccountToken(
    token: string,
    password: string
  ): Observable<Resource<UserModel>> {
    return this.resourceService.create<UserModel>(
      this.urlService.get("USERS.VERIFY_ACCOUNT"),
      { token, password },
      {
        headers: new HttpHeaders().append("no-error", "true"),
      }
    );
  }

  newVerificationCode(email: string): Observable<Resource<UserModel>> {
    return this.resourceService.create<any>(
      this.urlService.get("USERS.NEW_VERIFICATION_CODE"),
      { email }
    );
  }

  userDisableAccount(password: string): Observable<Resource<UserModel>> {
    return this.resourceService.create<any>(
      this.urlService.get("USERS.USER_DISABLE"),
      { password }
    );
  }

  getDocumentTypes(): Observable<Resource<DocumentTypeModel>> {
    return this.resourceService.list<DocumentTypeModel>(
      this.urlService.get("USERS.DOCUMENT_TYPES")
    );
  }
}
