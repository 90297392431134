import { first } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import * as _ from "lodash";
import { FiResourceService, FiUrlService, Resource } from "@fi-sas/core";
import { BaseRepositoryInterface } from "./base-repository.interface";
import { AutocompleItem } from "@fi-sas/webpage/core/models/autocomplete-item";
export class Repository<T> implements BaseRepositoryInterface<T> {
  /***
   * URL base
   */
  public entities_url = "";
  /***
   * URL with param id
   */
  public entity_url = "";
  public persistentUrlParams = {};
  public persistentHeaders = {};
  constructor(
    protected resourceService: FiResourceService,
    protected urlService: FiUrlService
  ) {}

  create(entity: Partial<T>): Observable<Resource<T>> {
    return this.resourceService
      .create<T>(
        this.urlService.get(this.entities_url, { ...this.persistentUrlParams }),
        entity,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  delete(id: number): Observable<Resource<any>> {
    return this.resourceService
      .delete(
        this.urlService.get(this.entity_url, {
          id,
          ...this.persistentUrlParams,
        }),
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  list(
    pageIndex: number,
    pageSize: number,
    sortKey?: string,
    sortValue?: string,
    params?: {},
    extraParams?: {}
  ): Observable<Resource<T>> {
    return this.resourceService
      .list<T>(
        this.urlService.get(this.entities_url, { ...this.persistentUrlParams }),
        {
          params: this.getQuery(
            pageIndex,
            pageSize,
            sortKey,
            sortValue,
            params,
            extraParams
          ),
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  read(id: number, params?: {}): Observable<Resource<T>> {
    return this.resourceService
      .read<T>(
        this.urlService.get(this.entity_url, {
          id,
          ...this.persistentUrlParams,
        }),
        {
          params: this.getQuery(null, null, null, null, params),
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  update(id: number, entity: Partial<T>): Observable<Resource<T>> {
    return this.resourceService
      .update<T>(
        this.urlService.get(this.entity_url, {
          id,
          ...this.persistentUrlParams,
        }),
        entity,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  patch(id: number, entity: {}): Observable<Resource<T>> {
    return this.resourceService
      .patch<any>(
        this.urlService.get(this.entity_url, {
          id,
          ...this.persistentUrlParams,
        }),
        entity,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  autocomplete(
    search?: string,
    language_id?: number
  ): Observable<Resource<AutocompleItem>> {
    let params = { search: search ? search : "" };
    if (language_id) params["language_id"] = language_id;
    return this.resourceService
      .list<AutocompleItem>(
        this.urlService.get(this.entities_url, {
          ...this.persistentUrlParams,
        }) + "/autocomplete",
        {
          params,
          headers: this.getHeaders(),
        }
      )
      .pipe(first());
  }

  protected getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    Object.keys(this.persistentHeaders).map((hk) => {
      headers = headers.append(hk, this.persistentHeaders[hk]);
    });
    return headers;
  }

  protected getQuery(
    pageIndex?: number,
    pageSize?: number,
    sortKey?: string,
    sortValue?: string,
    params?: {},
    extraParams?: {}
  ): HttpParams {
    let query = new HttpParams();
    if (pageIndex) {
      query = query.set("offset", ((pageIndex - 1) * pageSize).toString());
      query = query.set("limit", pageSize.toString());
    }
    if (sortKey) {
      if (sortValue === "ascend") {
        query = query.set("sort", sortKey);
      } else {
        query = query.set("sort", "-" + sortKey);
      }
    }
    if (extraParams) {
      let keys = Object.keys(extraParams);
      for (const key of keys) {
        let value = extraParams[key];
        query = query.append(key, value);
      }
    }
    if (params) {
      let keys = Object.keys(params);
      for (const key of keys) {
        let value = params[key];
        if (value !== null && value !== "") {
          if (Array.isArray(value)) {
            value = Array.from(new Set(value));
            value.map((v) =>
              [
                "withRelated",
                "searchFields",
                "search",
                "fields",
                "sort",
              ].includes(key)
                ? (query = query.set(key, value))
                : (query = query.append("query[" + key + "]", v))
            );
          } else if (typeof value === "object") {
            let value_keys = Object.keys(value);
            for (const vkey of value_keys) {
              query = query.set(
                "query[" + key + "][" + vkey + "]",
                value[vkey]
              );
            }
          } else {
            [
              "withRelated",
              "searchFields",
              "fields",
              "search",
              "sort",
            ].includes(key)
              ? (query = query.set(key, value))
              : (query = query.append("query[" + key + "]", value));
          }
        }
      }
    }
    return query;
  }
}
